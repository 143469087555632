import React, { useState, useEffect }  from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'components';
import { useReportResponseHumidityLog, useReportResponseTemperatureLog } from 'apollo/weather';
import Temperature from './temperature';
import Humidity from './humidity';
import { Filters } from './filters';

const stateName = 'environment.weather.historicLog';

export function GraphicWeatherRange() {
  const [threshold, setThreshold ] = useState({});
  const [yValuesMax, setYValuesMax ] = useState(0);
  const dataTemperature = useReportResponseTemperatureLog();
  const dataHumidity = useReportResponseHumidityLog();
  const { dataPoints } = useSelector(({ customers: { data } }) => data);

  const {
    environment: {
      weather: { historicLog: {  dataPoint } },
    },
  } = useSelector(({ state }) => state);

  useEffect(() => {
    if(dataPoint) {
      const data = dataPoints.find((key) => { return key._id === dataPoint})
      setYValuesMax(data.yValuesMax);
      setThreshold(data.threshold);
    }
  }, [dataPoint, dataPoints]);
  return (
    <Container>
      <Filters stateName={stateName} />
      <Temperature data={dataTemperature.data} loading={dataTemperature.loading} threshold= {threshold} yValuesMax={yValuesMax} />
      <Humidity data={dataHumidity.data} loading={dataHumidity.loading}  threshold={threshold} yValuesMax={yValuesMax} />
    </Container>
  );
}
